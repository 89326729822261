$(document).ready(function () {
  authenticate();
});

const encodeFormData = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

const refreshToken = async () => {
  const requestBody = {
    grant_type: "refresh_token",
    client_id: cognitoAppId,
    refresh_token: sessionStorage.getItem("refresh_token")
  };
  const authString = `${cognitoAppId}:${cognitoAppSecret}`;

  const res = await fetch(`${cognitoUrl}/oauth2/token`, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Basic ${btoa(authString)}`
    },
    body: encodeFormData(requestBody)
  });

  const data = await res.text();
  const { id_token, access_token } = JSON.parse(data);
  sessionStorage.setItem("id_token", id_token);
  sessionStorage.setItem("access_token", access_token);
};

const tokenCheck = async token => {
  const expireTime = JSON.parse(atob(token.split(".")[1])).exp;
  const date = new Date(expireTime * 1000);
  if (new Date() >= date) {
    await refreshToken();
  }
};

function login() {
  const url = new URL(window.location.href);
  const code = url.searchParams.get("code");
  if (!!code) {
    const requestBody = {
      grant_type: "authorization_code",
      client_id: cognitoAppId,
      code: code,
      redirect_uri: `${window.location.protocol}//${window.location.host}`
    };
    const authString = `${cognitoAppId}:${cognitoAppSecret}`;

    fetch(`${cognitoUrl}/oauth2/token`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Basic ${btoa(authString)}`
      },
      body: encodeFormData(requestBody)
    })
      .then(res => res.text())
      .then(data => {
        const { id_token, access_token, refresh_token } = JSON.parse(data);
        sessionStorage.setItem("id_token", id_token);
        sessionStorage.setItem("access_token", access_token);
        sessionStorage.setItem("refresh_token", refresh_token);
        window.location.assign(`${window.location.protocol}//${window.location.host}`);
      });
  } else {
    const rUri = encodeURI(`${window.location.protocol}//${window.location.host}`);
    const url = `${cognitoUrl}/oauth2/authorize?response_type=code&client_id=${cognitoAppId}&redirect_uri=${rUri}`;
    window.location.assign(url);
  }
}

async function authenticate() {
  const token = sessionStorage.getItem("id_token");
  if (!token) {
    login();
  }

  await tokenCheck(token);

  try {
    /*  oktaSignIn.session.get(function (res) {
      if (res.status === "ACTIVE") {*/
    if ($("body").is(":visible")) return;
    bindEvents();
    $("body").show();
    /*  } else {
        setState("offline");
        window.location.href = oktaConfig.loginURL;
      }
    });*/
  } catch (error) {
    login();
  }
}

function bindEvents() {
  // $("#searchContactInput").focus();
  setCcpUrl();
  //$("#ccpContainer").load("softphone.html");
  //$("#agentLogout").hide();
  //$("#loginuser").hide();
  $("*").on("focusin", function (event) {
    if (String(event.target.nodeName).toLowerCase() != "body") {
      event.target.style.background = "var(--theme-yellow)";
      event.target.style.color = "var(--theme-blue)";
    }
  });
  $("*").on("focusout", function (event) {
    event.target.style.background = "";
    event.target.style.color = "";
  });
  if (isTest) $("#version-label").text(versionDate);

  document.getElementById("searchform").addEventListener("submit", function (event) {
    event.preventDefault();
    let label = document.getElementById("searchContactLabel").textContent.toLowerCase();
    switch (label) {
      case "directory search":
        createContactTable();
        $("#accountSearchResults").hide();
        $("#accountsList").hide();
        $("#accountDetails").hide();
        $("#agentsView").hide();
        $("#contactsView").show();
        $("#salesforceButtonHome").show();
        $("#leaveCallBtn").hide();
        break;
      case "account search":
        searchCustomersByName();
        break;
      case "agents search":
        createAgentTable();
        $("#accountSearchResults").hide();
        $("#accountDetails").hide();
        $("#accountsList").hide();
        $("#contactsView").hide();
        $("#agentsView").show();
        break;
      default:
        break;
    }
  });
  document.getElementById("transfer-call-on-off-btn").addEventListener("click", function (event) {
    $("#transfer-call-on-off-btn").css("display", "none");
    $("#transfer-call-dial-form").show();
    $("#transfer-call-dial-input").focus();
  });
  document.getElementById("transfer-call-dial-form").addEventListener("submit", function (event) {
    event.preventDefault();
    let dialingNumber = document.getElementById("transfer-call-dial-input").value;
    let dialingCountry = document.getElementById("transfer-call-country-code").value;
    console.log(dialingNumber);
    if (!!dialingNumber) {
      transferManual(String(`${dialingCountry}${dialingNumber}`));
    }
  });
  document.getElementById("help-modal-close").addEventListener("click", function (event) {
    document.getElementById("helpModal").style.display = "none";
  });
}

var modal = document.getElementById("helpModal");

$("#help").click(function () {
  $("#helpModal").show();
  accessibleMessage("Shortcut list opened", "assertive");
});

// When the user clicks anywhere outside of the modal, close it
$(".close").click(function () {
  $("#helpModal").hide();
  accessibleMessage("Shortcut list closed", "assertive");
});

$(document).keydown(function (event) {
  if ((event.ctrlKey || event.metaKey) && event.which === 83) {
    $("#searchContactInput").focus();

    console.log("Ctrl + S");

    event.preventDefault();
    return false;
  }

  if (event.keyCode === 13 && event.originalEvent.path[0].id === "searchContactInput") {
    var searchContactInput = $("body").find(".searchButton.active").attr("id");
    if (searchContactInput != "undefined") {
      $("#" + searchContactInput).trigger("click");
    } else {
      $("#contactsButton").trigger("click");
    }
  }
});

$(document).on("#searchContactInput", "keydown", function (e) {});

doLogOut = async function () {
  console.log("doLogOut");
  await tokenCheck(sessionStorage.getItem("id_token"));
  fetch(urlBase.updateAgentUrl, {
    method: "POST",
    mode: "cors",
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("id_token")}`
    },
    body: JSON.stringify({
      name: getAgentInfo().getConfiguration().username,
      agentstatus: "Offline"
    })
  })
    .then(res => res.text())
    .then(data => {
      console.log("agent status change success");
    })
    .catch(err => console.log(err));
  sessionStorage.clear();
  setState("offline");
  const ccplogoutWindow = window.open(configVision.ccpLogout, "_blank");
  const logoutUri = encodeURI(`${window.location.protocol}//${window.location.host}/logout.html`);
  setTimeout(function () {
    ccplogoutWindow.close();
    window.location.href = `${cognitoUrl}/logout?client_id=${cognitoAppId}&logout_uri=${logoutUri}`;
  }, 1000);
  /*

    updatedAgent = null;
    lastAgentStatus = null;
    lastFirstConnectionStatus = null;
    lastTransferStatus = null;

    $('#contactsButton').prop('disabled', true);
    $('#contactsButton').css('cursor', 'none');

    $('#accountsButton').prop('disabled', true);
    $('#accountsButton').css('cursor', 'none');

    $('#searchContactInput').val('');
    $('#searchContactInput').prop('disabled', true);

    updateSoftphoneView("none");

    $('#contactsView').hide();
    $('#accountsList').hide();
    $('#accountDetails').hide();

    $('.agent-status-container').hide();

    $('#loginuser').hide();
    $('#agentLogout').hide();

    $('#loginbutton').show();

    document.title = 'Login';
    */
  //}
};

createAgentTable = async function () {
  function agentRow(agent) {
    // console.log(agent);
    return `
    <tr  class="selectable-row" tabindex=0>
    <td>${!agent.name ? "" : agent.name}</td>
    ${
      String(agent.status).toLowerCase() === "available"
        ? `<td><span class='badge badge-primary badge-on'>`
        : `<td><span class='badge badge-primary badge-off'>`
    }
   ${agent.status}</span></td>
   ${
     getNumberOfConnectedCall() === 1
       ? `<td style="text-align: center; font-size:1.5rem;">
        <a>
      ${
        agent.status === "available"
          ? `<button type="button" class="btn btn-primary" aria-label="call agent '${agent.name}'"  onclick="transferToAgent('${agent.id}')">Call agent</button>`
          : "Agent is unavailable"
      }
      </a></td>
      `
       : `<td></td>`
   }
   ${
     /*`<td style="text-align: center; font-size:1.5rem;">
       <a>
     ${
       agent.status === "available"
         ? `<button type="button" class="btn btn-primary" aria-label="call agent '${agent.name}'"  onclick="CallAgentDirect('${agent.id}')">Call agent</button>`
         : "Agent is unavailable"
     }
     </a></td>
    `*/ ``
   }
   </tr>
    `;
  }
  let searchName = document.getElementById("searchContactInput").value;
  $("#agentsLoader").show();
  $("#agentNotFoundMessage").hide();
  $("#agentsSearchResults").hide();
  $("#agentsTable tbody").empty();

  searchName = !searchName ? "@all" : searchName;
  await tokenCheck(sessionStorage.getItem("id_token"));
  fetch(urlBase.fetchAgentUrl, {
    method: "POST",
    mode: "cors",
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("id_token")}`
    },
    body: JSON.stringify({ searchKey: searchName })
  })
    .then(res => res.text())
    .then(response => {
      // filter out agent himself
      console.log(JSON.parse(response));
      const data = JSON.parse(response);
      console.log(getAgentInfo().getConfiguration());
      let agentsInfo = data.filter(agent => agent["ID"] !== getAgentInfo().getConfiguration().username);
      if (agentsInfo.length === 0) {
        $("#agentsLoader").hide();
        $("#agentNotFoundMessage").show();
      } else {
        let finalAgents = agentsInfo.map(agent => ({
          id: String(agent["ID"]),
          name: String(agent.fullName),
          status: String(agent.agentStatus).toLowerCase(),
          arn: String(agent.arn)
        }));

        let availableAgent = finalAgents
          .filter(a => a.status === "available")
          .sort((a, b) => (String(a.name).toLowerCase() > String(b.name).toLowerCase() ? 1 : -1));

        let offlineAgent = finalAgents
          .filter(a => a.status !== "available")
          .sort((a, b) => (String(a.name).toLowerCase() > String(b.name).toLowerCase() ? 1 : -1));

        $("#agentsTable tbody").append([...availableAgent, ...offlineAgent].map(agent => agentRow(agent)));
        $("#agentNotFoundMessage").hide();
        $("#agentsLoader").hide();
        $("#agentsSearchResults").show();
        $("#agentsTable").find("tbody").find("tr:first").focus();
      }
    })
    .catch(err => console.log(err));
};
showAgentsView = function (obj) {
  console.log("showAgentsView");
  $(".nav-link.bar-link").removeClass("active-nav-link");

  $(`#${obj.id}`).addClass("active-nav-link");
  $("#searchContactLabel").text("Agents Search");
  $(".bar-link").removeAttr("aria-current");
  $("#" + obj.id).attr("aria-current", "true");
  if (!!getAgentInfo()) {
    createAgentTable();
    $("#accountSearchResults").hide();
    $("#accountDetails").hide();
    $("#accountsList").hide();
    $("#contactsView").hide();
    $("#agentsView").show();
  }
};

showCustomersView = function () {
  console.log("showCustomersView");
  $(".nav-link.bar-link").removeClass("active-nav-link");

  $(`#accountsButton`).addClass("active-nav-link");
  $("#searchContactLabel").text("Account Search");
  if (!!getAgentInfo()) {
    searchCustomersByName();
    $(".bar-link").removeAttr("aria-current");
    $("#accountsButton").attr("aria-current", "true");
    $("#accountSearchResults").hide();
    $("#accountDetails").hide();
    $("#contactsView").hide();
    $("#agentsView").hide();
    $("#accountsList").show();
    $("#salesforceButtonHome").show();
  }

  //$('#inactiveSoftphone').show();
  // $('#searchAccountInput').focus();
};
createContactTable = async function () {
  let searchName = document.getElementById("searchContactInput").value;
  console.log(searchName);
  searchName = !searchName ? "anne" : searchName;

  $("#contactsLoader").show();
  $("#contactsNotFoundMessage").hide();
  $("#contactSearchResults").hide();
  $("#contactsTable tbody").empty();
  await tokenCheck(sessionStorage.getItem("id_token"));
  fetch(urlBase.fetchContactUrl, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("id_token")}`
    },
    body: JSON.stringify({ searchKey: searchName })
  })
    .then(res => res.text())
    .then(data => {
      let contacts = JSON.parse(data);
      console.log(contacts[0]);
      if (contacts.length === 0) {
        $("#contactsLoader").hide();
        $("#contactsNotFoundMessage").show();
      } else {
        contacts.forEach(function (contact) {
          var tableRow =
            '<tr  class="selectable-row" tabindex=0>' +
            "<td>" +
            (contact.givenName != null && contact.sn != null ? contact.givenName + " " + contact.sn : "") +
            "</td>" +
            '<td style="cursor: pointer;" ><a onclick="accountPlaceCall(\'' +
            contact.telephoneNumber +
            "');\">" +
            (contact.telephoneNumber != null ? contact.telephoneNumber : "") +
            "</a></td>" +
            '<td style="cursor: pointer;" ><a onclick="accountPlaceCall(\'' +
            contact.mobile +
            "');\">" +
            (contact.mobile != null ? contact.mobile : "") +
            "</a></td>" +
            "<td>" +
            (contact.OfficeName != null ? contact.OfficeName : "") +
            "</td>" +
            "<td>" +
            (contact.title != null ? contact.title : "") +
            "</td>" +
            "</tr>";
          $("#contactsView tbody").append(tableRow);
        });

        $("td").find("a:empty").remove();

        $("#contactsLoader").hide();
        $("#contactsNotFoundMessage").hide();
        $("#contactSearchResults").show();
        $("#contactsTable").find("tbody").find("tr:first").focus();
      }
    })
    .catch(err => console.log(err));
};
showContactsView = function (obj) {
  console.log("showContactsView");
  $(".nav-link.bar-link").removeClass("active-nav-link");
  $(`#${obj.id}`).addClass("active-nav-link");

  $("#searchContactLabel").text("Directory Search");
  // scanData();
  $(".bar-link").removeAttr("aria-current");
  $("#" + obj.id).attr("aria-current", "true");
  if (!!getAgentInfo()) {
    createContactTable();
    $("#accountSearchResults").hide();
    $("#accountsList").hide();
    $("#accountDetails").hide();
    $("#agentsView").hide();
    $("#contactsView").show();
    $("#salesforceButtonHome").show();
    $("#leaveCallBtn").hide();
  }
};

inputKeyPress = function (e) {
  if (e.which == 13) {
    placeCall();
  }
};

searchCustomersByName = function () {
  console.log("searchCustomersByName");

  var accountName = $("#searchContactInput").val();
  if (accountName != null && accountName.length >= 3) {
    searchCustomers({ searchKey: `Name like '%${accountName}%'` });
    $(".bar-link").removeAttr("aria-current");
    $("#accountsButton").attr("aria-current", "true");
    $("#accountSearchResults").hide();
    $("#accountDetails").hide();
    $("#contactsView").hide();
    $("#agentsView").hide();
    $("#accountsList").show();
    $("#salesforceButtonHome").show();
  }
};

searchCustomersByPhoneNumber = function (phoneNumber) {
  console.log({ x: "searchCustomersByPhoneNumber", phoneNumber });

  if (phoneNumber.includes("+")) {
    phoneNumber = phoneNumber.substring(4);
  }

  searchCustomers({ searchKey: `PersonMobilePhone like '%${phoneNumber}%'` });
  $(".bar-link").removeAttr("aria-current");
  $("#accountsButton").attr("aria-current", "true");
  $("#accountSearchResults").hide();
  $("#accountDetails").hide();
  $("#contactsView").hide();
  $("#agentsView").hide();
  $("#accountsList").show();
  $("#salesforceButtonHome").show();
};

var accounts;
var selectedAccount;

searchCustomers = async function (bodyParam) {
  console.log({ x: "searchCustomers", bodyParam });

  accessibleMessage("Searching accounts", "assertive");

  $("#accountsNotFoundMessage").hide();
  $("#accountSearchResults").hide();
  $("#accountsLoader").show();
  await tokenCheck(sessionStorage.getItem("id_token"));
  fetch(urlBase.fetchAccountUrl, {
    method: "post",
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("id_token")}`
    },
    body: JSON.stringify(bodyParam)
  })
    .then(res => res.text())
    .then(resp => {
      const data = JSON.parse(resp);
      let response = { accounts: [] };
      console.log(data);
      response.accounts = data.records;
      console.log(response.accounts);
      $("#accountsLoader").hide();

      if (response.accounts != null && response.accounts.length > 0) {
        // console.log(response.accounts);
        //SELECT Id,Name,PersonEmail,Residential_Address__pc,PersonHomePhone,PersonBirthdate FROM Account WHERE Name LIKE
        accounts = response.accounts;
        //  [{
        //       Id: "0012800000oxxeHAAQ",
        //       Name: "Aaron Johnstone",
        //       PersonEmail: null,
        //       Residential_Address__pc:
        //         "90 Lovetts Road, HAMILTON, Victoria, 3300, Australia",
        //       PersonHomePhone: null,
        //       PersonBirthdate: null
        //     }];
        // If there is only one account, display the account details.
        if (response.accounts.length == 1) {
          showCustomerDetail(response.accounts[0].Id);
        } else {
          $("#accountsTable tbody").empty();

          for (var i = 0; i < accounts.length; i++) {
            var PersoonBirthdate;
            if (typeof accounts[i] !== "undefined") {
              PersoonBirthdate = convertDate(accounts[i].PersonBirthdate);
            }
            var tableRow =
              '<tr class="selectable-row" tabindex=0>' +
              "<td><a onclick=\"showCustomerDetail('" +
              accounts[i].Id +
              "');\">" +
              (accounts[i].Name != null ? accounts[i].Name : "") +
              "</a></td>" +
              '<td style="cursor: pointer;" ><a onclick="accountPlaceCall(\'' +
              accounts[i].PersonHomePhone +
              "');\">" +
              (accounts[i].PersonHomePhone != null ? accounts[i].PersonHomePhone : "") +
              "</a></td>" +
              '<td style="cursor: pointer;" ><a onclick="accountPlaceCall(\'' +
              accounts[i].PersonMobilePhone +
              "');\">" +
              (accounts[i].PersonMobilePhone != null ? accounts[i].PersonMobilePhone : "") +
              "</a></td>" +
              "<td >" +
              (accounts[i].Residential_Address__pc != null ? accounts[i].Residential_Address__pc : "") +
              "</td>" +
              "<td>" +
              (accounts[i].PersonEmail != null ? accounts[i].PersonEmail : "") +
              "</td>" +
              "<td>" +
              (accounts[i].PersonBirthdate != null ? PersoonBirthdate : "") +
              "</td>" +
              "</tr>";

            $("#accountsTable tbody").append(tableRow);
            $("td").find("a:empty").remove();
            //console.log(accounts[i]);
          }

          // $("#accountsTable tr").keypress(function (e) {
          //   if (e.which == 13) {
          //     $(e.target).click();
          //   }
          // });

          $("#accountSearchResults").show();

          $("#accountsTable").find("tbody").find("tr:first").focus();
        }
      } else {
        $("#accountsNotFoundMessage").show();
      }
    })
    .catch(err => {
      console.log(err);
      $("#accountsLoader").hide();
      $("#accountsNotFoundMessage").show();
    });
};

convertDate = function (date) {
  // console.log({ x: "convertDate", date });

  var Persondate = new Date(date);
  var person_date;
  var person_month;

  if (Persondate.getDate() < 10) {
    person_date = "0" + Persondate.getDate();
  } else {
    person_date = Persondate.getDate();
  }

  if (Persondate.getMonth() + 1 < 10) {
    person_month = "0" + (Persondate.getMonth() + 1);
  } else {
    person_month = Persondate.getMonth() + 1;
  }

  var person_year = Persondate.getFullYear();
  return person_date + "-" + person_month + "-" + person_year;
};

showCustomerDetail = async function (accountOid) {
  console.log({ x: "showCustomerDetail", accountOid });

  $("#accountSearchResults").hide();
  $("#accountsList").hide();
  $("#contactsView").hide();

  $("#accountDetailsLoader").show();
  await tokenCheck(sessionStorage.getItem("id_token"));
  $.ajax({
    url: urlBase.fetchAccountUrl,
    type: "POST",
    beforeSend: xhr => {
      xhr.setRequestHeader("Authorization", `Bearer ${sessionStorage.getItem("id_token")}`);
    },
    data: JSON.stringify({ searchKey: accountOid, action: "notes" }),
    success: function (data) {
      $("#accountDetailsLoader").hide();

      if (data != null) {
        const response = data;
        for (var i = 0; i < accounts.length; i++) {
          if (accounts[i].Id == accountOid) {
            selectedAccount = accounts[i];
            break;
          }
        }

        console.log(selectedAccount);
        // Build account details table.
        $("#accountDetailsTable").empty();
        var PersoonBirthdate = convertDate(selectedAccount.PersonBirthdate);

        var tableBody =
          '<tr tabindex=0><th>Account Name</th><td><a onclick="openAccountInSalesforce();">' +
          (selectedAccount.Name != null ? selectedAccount.Name : "") +
          "</a></td></tr>" +
          "<tr><th>Email</th><td>" +
          (selectedAccount.PersonEmail != null ? selectedAccount.PersonEmail : "") +
          "</td></tr>" +
          "<tr><th>Address</th><td>" +
          (selectedAccount.Residential_Address__pc != null ? selectedAccount.Residential_Address__pc : "") +
          "</td></tr>" +
          '<tr><th>Phone</th><td style="cursor: pointer;" onclick="accountPlaceCall(\'' +
          selectedAccount.PersonHomePhone +
          "');\">" +
          (selectedAccount.PersonHomePhone != null ? selectedAccount.PersonHomePhone : "") +
          "</td></tr>" +
          '<tr><th>Mobile</th><td style="cursor: pointer;" onclick="accountPlaceCall(\'' +
          selectedAccount.PersonMobilePhone +
          "');\">" +
          (selectedAccount.PersonMobilePhone != null ? selectedAccount.PersonMobilePhone : "") +
          "</td></tr>" +
          "<tr><th>Birthdate</th><td>" +
          (selectedAccount.PersonBirthdate != null ? PersoonBirthdate : "") +
          "</td></tr>";

        $("#accountDetailsTable").append(tableBody);

        // Build alerts table.
        $("#alertsTable tbody").empty();
        if (response.alerts != null && response.alerts.length > 0) {
          var alerts = response.alerts;

          for (var i = 0; i < alerts.length; i++) {
            var Alert_Expiry_Date__c = convertDate(alerts[i].Alert_Expiry_Date__c);
            var tableRow =
              "<tr>" +
              "<td>" +
              (alerts[i].Alert_Type__c != null ? alerts[i].Alert_Type__c : "") +
              "</td>" +
              "<td>" +
              (alerts[i].Alert_Description__c != null ? alerts[i].Alert_Description__c : "") +
              "</td>" +
              "<td>" +
              (alerts[i].Alert_Expiry_Date__c != null ? alerts[i].Alert_Expiry_Date__c : "") +
              "</td>" +
              "</tr>";

            $("#alertsTable tbody").append(tableRow);
            $("#alertsTable").show();
          }
        } else {
          $("#alertHeading").hide();
          $("#alertsTable").hide();
        }

        // Build case notes table.
        $("#caseNotesTable tbody").empty();
        if (response.alerts != null && response.caseNotes.length > 0) {
          var caseNotes = response.caseNotes;

          for (var i = 0; i < caseNotes.length; i++) {
            var Created_Date = convertDate(caseNotes[i].Created_Date__c);
            var tableRow =
              "<tr>" +
              "<td>" +
              (caseNotes[i].Name != null ? caseNotes[i].Name : "") +
              "</td>" +
              "<td>" +
              (caseNotes[i].Notes__c != null ? caseNotes[i].Notes__c : "") +
              "</td>" +
              "<td>" +
              (caseNotes[i].Service_Delivered__r != null ? caseNotes[i].Service_Delivered__r.Name : "") +
              "</td>" +
              "<td>" +
              (caseNotes[i].Item_Description_L3__c != null ? caseNotes[i].Item_Description_L3__c : "") +
              "</td>" +
              "<td>" +
              (caseNotes[i].Service_Provider__c != null ? caseNotes[i].Service_Provider__c : "") +
              "</td>" +
              "<td>" +
              (caseNotes[i].CreatedBy.Name != null ? caseNotes[i].CreatedBy.Name : "") +
              "</td>" +
              "<td>" +
              (caseNotes[i].Created_Date__c != null ? caseNotes[i].Created_Date__c : "") +
              "</td>" +
              "<td>" +
              (caseNotes[i].Private__c != null ? Created_Date : "") +
              "</td>" +
              "</tr>";

            $("#caseNotesTable tbody").append(tableRow);
            $("#caseNotesTable").show();
          }
        } else {
          $("#caseNotesHeading").hide();
          $("#caseNotesTable").hide();
        }

        // Build support tickets table.
        $("#supportTicketsTable tbody").empty();
        if (response.alerts != null && response.supportTickets.length > 0) {
          var supportTickets = response.supportTickets;

          for (var i = 0; i < supportTickets.length; i++) {
            var Due_Date__c = convertDate(supportTickets[i].Due_Date__c);
            var tableRow =
              "<tr>" +
              "<td>" +
              (supportTickets[i].Subject__c != null ? supportTickets[i].Subject__c : "") +
              "</td>" +
              "<td>" +
              (supportTickets[i].Description__c != null ? supportTickets[i].Description__c : "") +
              "</td>" +
              "<td>" +
              (supportTickets[i].Status__c != null ? supportTickets[i].Status__c : "") +
              "</td>" +
              "<td>Medium</td>" +
              "<td>" +
              (supportTickets[i].Due_Date__c != null ? Due_Date__c : "") +
              "</td>" +
              "<td>" +
              (supportTickets[i].Comments__c != null ? supportTickets[i].Comments__c : "") +
              "</td>" +
              "</tr>";

            $("#supportTicketsTable tbody").append(tableRow);
            $("#supportTicketsTable").show();
          }
        } else {
          $("#supportTicketsHeading").hide();
          $("#supportTicketsTable").hide();
        }
        $("#salesforceButtonHome").hide();

        $("#accountDetails").show();
        $("#alertsTable").show();
        $("#accountDetails").show();
        $("#accountDetailsTable").find("tr:first").focus();
      }
    },
    error: function (xhr, ajaxOptions, thrownError) {
      $("#accountDetailsLoader").hide();
    }
  });
};

openAccountInSalesforce = function () {
  console.log("openAccountInSalesforce");
  window.open(urlBase.salesforce + selectedAccount.Id);
};

openAccountAgentInSalesforce = function () {
  console.log("openAccountAgentInSalesforce");
  window.open(urlBase.salesforce);
};

accessibleMessage = function (msg, type) {
  console.log({ x: "accessibleMessage", msg, type });

  // msg is a string to be announced
  // type is alert, assertive or polite

  // create the message span and styles
  var messageSpan = document.createElement("span");
  messageSpan.id = "scriptMessage";

  if (type === "alert") messageSpan.setAttribute("role", "alert");
  else messageSpan.setAttribute("aria-live", type);

  messageSpan.setAttribute(
    "style",
    "clip: rect(1px, 1px, 1px, 1px); height: 1px; overflow: hidden; position: absolute; white-space: nowrap; width: 1px"
  );

  // attach it to the dom
  document.body.appendChild(messageSpan);

  window.setTimeout(function () {
    // insert the message into the span
    messageSpan.textContent = msg;
    // needed to get role="alert" to work in chrome, firefox requires the version above and chrome requires it to be inserted here
    if (type === "alert") {
      messageSpan.removeAttribute("role");
      messageSpan.setAttribute("role", "alert");
    }
    window.setTimeout(function () {
      messageSpan.remove();
    }, 100); // for the remove
  }, 50); // for adding message
};
